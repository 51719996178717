import { ref } from "vue";



interface Passwords{
    password : string;
    password_confirmation : string;
}


export const passwords = ref< Passwords >( {
    password : "",
    password_confirmation : "",
} );


interface Validation {
    password : ({
        required : boolean;
        message : string;
        trigger : string;
    } | {
        min : number;
        max : number;
        message : string;
        trigger : string;
    })[];
    password_confirmation : ({
        required : boolean;
        message : string;
        trigger : string;

    } | {
        min : number;
        max : number;
        message : string;
        trigger : string;

    } | {
        validator : ( rule : Validation, value : string, callback : any ) => void;
        trigger : string;
    })[];
}



const passwordConfirmation = ( rule : Validation, value : string, callback : any ) => {
    if ( value === "" ) {
        callback( new Error( "请再次输入密码" ) );
    } else if ( value !== passwords.value.password ) {
        callback( new Error( "两次输入密码不一致!") );
    } else {
        callback();
    }
};



export const passwordValidation = ref< Validation >( {
    password : [
        {
            required : true,
            message : "Password could not be empty...",
            trigger : "blur",
        },
        {
            min : 6,
            max : 30,
            message : "Password's length has to be 6 to 30 characters...",
            trigger : "blur",
        },
    ],
    password_confirmation : [
        {
            required : true,
            message : "Password confirmation could not be empty...",
            trigger : "blur",
        },
        {
            min : 6,
            max : 30,
            message : "Password's length has to be 6 to 30 characters...",
            trigger : "blur",
        },
        { validator : passwordConfirmation, trigger : "blur" },
    ]
} );
