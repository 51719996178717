
import { ref, getCurrentInstance } from "vue"
import Layout from "@components/Layout.vue"
import { passwords, passwordValidation } from "@utils/PasswordValidator.ts";
import { ElMessage } from 'element-plus'

export default {
    name: "ChangePassword",
    components : { Layout },
    data() {
        return {
            passwords : passwords,
            passwordValidation : passwordValidation
        }
    },
    setup(){
        // @ts-ignore
        const { proxy } = getCurrentInstance();

        const changePassword = ( formName : string ) => {
            proxy.$refs[ formName ].validate( async ( valid ) => {
                if ( valid ) {
                    let data = {
                        password : passwords.value.password,
                        password_confirmation : passwords.value.password_confirmation
                    }

                    await proxy.$post( '/settings/password', data )
                        .then( res => {
                            // redirect to homepage
                            ElMessage( { message: res.message, type: 'success', showClose : true } )
                            proxy.$refs[ formName ].resetFields()
                        } )

                } else {
                    ElMessage( { message: 'Validation errors', type: 'error', showClose : true } )
                    return false
                }
            } )
        }

        return { changePassword };
    }
}

